// @flow

import React from 'react';
import {InlineBlock} from 'jsxstyle';
import {useSpring, animated} from 'react-spring';

import {ORANGE} from '@styles/colors';

import type {Element} from 'react';

type Props = {
  color?: string,
  marginBottom?: string,
  marginTop?: string,
};

const mediaQueries = {
  sm: 'screen and (max-width: 768px)',
  lg: 'screen and (min-width: 1280px)',
};

const AnimatedDivider = animated(InlineBlock);

export default function Divider({
  color,
  marginBottom,
  marginTop,
}: Props): Element<*> {
  const {width} = useSpring({
    from: {
      width: '0%',
    },
    to: {
      width: '60%',
    },
    delay: 1000,
  });
  return (
    <AnimatedDivider
      width={width.interpolate(v => `${v}`)}
      background={color ? color : ORANGE}
      height="3px"
      marginBottom={marginBottom ? marginBottom : '40px'}
      marginTop={marginTop ? marginTop : null}
      mediaQueries={mediaQueries}
      smMargin="10px 0 25px 0"
    />
  );
}
