// @flow

import React from 'react';
import {Col} from 'jsxstyle';

import Eyebrow from '@components/Eyebrow';
import ComingSoon from '@components/ComingSoon';
import Headline from '@components/Headline';
import Paragraph from '@components/Paragraph';
import Divider from '@components/Divider';
import SafeHTML from '@components/SafeHTML';

import {BLUE} from '@styles/colors';

import type {Element} from 'react';

type Props = {
  width?: string,
  margin?: string,
  eyebrow?: string,
  headline?: string,
  copy?: string,
  dividerColor?: string,
  copyColor?: string,
  children?: any,
  button?: any,
  alignItems?: string,
  textAlign?: string,
  eyebrowColor?: string,
  dangerousCopy?: boolean,
  sentenceCase?: boolean,
  mq?: Object,
  textTransform?: string,
  comingSoon?: boolean
};

const mediaQueries = {
  sm: 'screen and (max-width: 1024px)',
  lg: 'screen and (min-width: 1280px)',
};

export default ({
  width,
  margin,
  eyebrow,
  headline,
  copy,
  button,
  dividerColor,
  copyColor,
  children,
  alignItems,
  textAlign,
  eyebrowColor,
  dangerousCopy,
  sentenceCase,
  textTransform,
  comingSoon,
  ...rest
}: Props): Element<*> => (
  <Col
    width={width ? width : '40%'}
    margin={margin ? margin : '0 auto'}
    alignItems={alignItems ? alignItems : 'center'}
    justifyContent="center"
    mediaQueries={mediaQueries}
    smPadding="20px"
    smWidth="calc(90% - 10px) !important"
    smAlignItems={alignItems ? alignItems : 'center'}
    {...rest}
  >
    {comingSoon ? (
      <ComingSoon>
      </ComingSoon>
    )
    :null}
    {eyebrow ? (
      <Eyebrow textAlign="left" color={eyebrowColor ? eyebrowColor : copyColor} width="100%">
        {eyebrow}
      </Eyebrow>
    ) : null}
    {headline ? (
      <Headline
        color={copyColor}
        textAlign={textAlign ? textAlign : 'center'}
        margin="0"
        width="100%"
        sentenceCase={sentenceCase}
        textTransform={textTransform}
      >
        {headline}
      </Headline>
    ) : null}
    {dividerColor && <Divider color={dividerColor} />}
    {copy ? (
      dangerousCopy ? (
        <Col
          width="100%"
          color={BLUE}
          textAlign={textAlign ? textAlign : 'center'}
          margin="0 0 40px 0"
          listStyle="inside disc"
          smMargin="0 0 20px 0"
          lineHeight="1.5rem"
        >
          <SafeHTML>{copy}</SafeHTML>
        </Col>
      ) : (
        <Paragraph
          width="100%"
          color={copyColor}
          textAlign={textAlign ? textAlign : 'center'}
          margin="0 0 40px 0"
          smMargin="0 0 20px 0"
        >
          {copy}
        </Paragraph>
      )
    ) : null}
    {children}
    {button ? button : null}
  </Col>
);
