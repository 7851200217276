// @flow

import React from 'react';
import {Block} from 'jsxstyle';

import {ORANGE} from '@styles/colors';

import type {Element} from 'react';

type Props = {
  color?: string,
  textAlign?: string,
  children: any,
  whiteSpace?: string,
  margin?: string,
};

const mediaQueries = {
  sm: 'screen and (max-width: 1024px)',
  lg: 'screen and (min-width: 1280px)',
};

export default ({
  color,
  textAlign,
  whiteSpace,
  margin,
  children,
}: Props): Element<*> => (
  <Block
    component="h4"
    color={color ? color : ORANGE}
    textTransform="uppercase"
    margin={margin ? margin : '20px 0 30px 0'}
    fontSize="1.2rem"
    fontWeight="bold"
    whiteSpace={whiteSpace}
    textAlign={textAlign ? textAlign : 'center'}
    mediaQueries={mediaQueries}
    smMargin={'10px 0'}
    smTextAlign="left"
  >
    {children}
  </Block>
);
