// @flow

import React from 'react';
import {Block} from 'jsxstyle';

import {ORANGE, WHITE} from '@styles/colors';
import warningIcon from '@data/svg/Warning.svg';
import Image from '@components/Image';

import type {Element} from 'react';


const mediaQueries = {
  sm: 'screen and (max-width: 1024px)',
  md: 'screen and (max-width: 1279px) and (min-width: 1025px)',
  lg: 'screen and (min-width: 1280px)',
};

export default (): Element<*> => (
 <Block
    component="div"
    color={WHITE}
    backgroundColor={ORANGE}
    margin={'0 0 20px 0'}
    fontSize="2rem"
    fontWeight="500"
    textAlign={'left'}
    mediaQueries={mediaQueries}
    smMargin={'10px 0'}
    smTextAlign="left"

    padding={'15px 0px'}
    overflow='visible'
    position='relative'

    lgLeft='calc(-25vw + 50%)'
    lgWidth="50vw"
    mdLeft='calc(-25vw + 50%)'
    mdWidth="50vw"
    smLeft='calc(-50vw + 50%)'
    smWidth="100vw"
  >
    <Block 
    component="h2"
    mediaQueries={mediaQueries}
    margin="0px auto"
    lgWidth="80%"
    mdWidth="85%"
    smWidth="90%"
    display={'flex'}
    textAlign="left">
    
    <Image
        verticalAlign={'middle'}
        imgMaxHeight={'24px'}
        imgMaxWidth={'24px'}
        imageSrc={warningIcon}
        position={'absolute'}
        top={'50%'}
        margin={'0px 5px'}
        lgMarginLeft={'50px'}
      />
        Coming Soon
      </Block>
  </Block>

);
