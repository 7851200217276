// @flow

import React from 'react';
import {Block} from 'jsxstyle';
import Img from 'gatsby-image';

import type {Element} from 'react';

type Props = {
  imageSrc?: string,
  zIndex?: number,
  width?: string,
  height?: string,
  alt?: string,
  mediaQueries?: Object,
  smWidth?: string,
  smHeight?: string,
  fluid?: Object,
  headline?: string,
  style?: Object,
  imgStyle?: Object,
  imgMaxHeight?: string,
  imgMaxWidth?: string,
  smMaxWidth?: string,
  key?: string,
  margin?: string,
  lgWidth?: string,
  lgHeight?: string,
  objectFit?: string,
  objectPosition?: string,
};

export default ({
  fluid,
  imageSrc,
  headline,
  mediaQueries,
  zIndex,
  width,
  height,
  alt,
  smWidth,
  smHeight,
  lgWidth,
  lgHeight,
  style,
  imgStyle,
  imgMaxHeight,
  imgMaxWidth,
  smMaxWidth,
  margin,
  key,
  objectFit,
  objectPosition
}: Props): Element<*> | null => {
  if (imageSrc) {
    return (
      <Block
        component="img"
        key={key}
        width={width ? width : '100%'}
        height={height ? height : 'auto'}
        props={{src: imageSrc, alt: alt ? alt : headline, draggable: false}}
        zIndex={zIndex ? zIndex : 0}
        maxHeight={imgMaxHeight}
        maxWidth={imgMaxWidth}
        mediaQueries={mediaQueries}
        smWidth={smWidth ? smWidth : width}
        smHeight={smHeight ? smHeight : height}
        lgWidth={lgWidth ? lgWidth : width}
        lgHeight={lgHeight ? lgHeight : height}
        smMaxWidth={smMaxWidth}
        margin={margin ? margin : '0'}
        objectFit={objectFit}
        objectPosition={objectPosition}
        style={{...style}}
      />
    );
  } else if (fluid) {
    return (
      <Img
        fluid={fluid}
        alt={headline}
        imgStyle={{...imgStyle}}
        style={{...style}}
      />
    );
  } else return null;
};
