// @flow

import queryString from 'query-string';

import type {
  AddressType,
  FloorplanType,
  UnitType,
  NeighborhoodType,
} from '@types-local';

export const propertiesToGenerate = ['the victory', 'the epic', '345e94', '298 mulberry'];

export function propertyHasUnits(property: Object) {
  if (propertiesToGenerate.includes(property.name.toLowerCase())) {
    return true;
  }
  if (!property.soldout) {
    const {floorplans} = property;
    const floorplanUnits = floorplans.filter(
      floorplan => floorplan.units && floorplan.units.length > 0,
    );
    const availableUnits = floorplanUnits
      .map(unit => {
        const {units} = unit;
        const filteredUnits = units.filter(u => u.available);
        return filteredUnits;
      })
      .filter(ar => ar.length > 0)
      .reduce(flatten, [])
      .reduce(makeUniqueIds, []);
    return availableUnits.length > 0 ? true : false;
  }
}

export function objectToString(object: AddressType): string {
  let newObject = {};
  if (object.neighborhood) {
    newObject = object;
    delete newObject.neighborhood;
    return [...Object.values(newObject)].join(', ');
  } else {
    return [...Object.values(object)].join(', ');
  }
}

export function addressToGoogleFriendlyAddress(string: string): string | null {
  if (string) {
    return string.toLowerCase().replace(/,/g, '+');
  } else {
    return null;
  }
}

export function getFloorFromUnit(object: FloorplanType): string | null {
  if (Object.keys(object).length < 1) {
    return null;
  }
  if (object.isPenthouse) {
    return 'Penthouse';
  }
  const {name} = object;
  if (!name) {
    return null;
  }
  if (name.includes('Floor') || name.includes('Floors')) {
    // eslint-disable-next-line no-useless-escape
    const parsedName = name.match(/\d+\-?\d?\d?\P?\H?/);
    let floorFromName;
    if (Array.isArray(parsedName)) {
      [floorFromName] = parsedName;
    } else {
      return null;
    }
    const floor = name.includes('Floors')
      ? `Floors ${floorFromName}`
      : `Floor ${floorFromName}`;
    return floor;
  } else {
    return null;
  }
}

export function getPropertyNameFromUnit(object: UnitType): string | null {
  if (Object.keys(object).length < 1) {
    return null;
  }
  const {name} = object;
  if (!name) {
    return null;
  }
  if(name.indexOf("Residence") != -1){
    // eslint-disable-next-line no-useless-escape
    const match = name.match(/[^,]*/);
    let propertyName;
    if (Array.isArray(match)) {
      [propertyName] = match;
      return propertyName;
    } else {
      return null;
    }
  }else{
    // eslint-disable-next-line no-useless-escape
    const nameSplit = name.split("Line")
    if (nameSplit.length > 1) {
      return "Residence " + nameSplit[0] + "Line";
    } else {
      return null;
    }
  }
}

export function stringToUrl(string: string): string {
  if (string) {
    return string
      .toLowerCase()
      .replace(/'/g, '')
      .replace(/\//g, ' ')
      .replace(/\(/g, ' ')
      .replace(/\)/g, ' ')
      .replace(/,/g, ' ')
      .replace(/ /g, '-')
      .replace(/--/g, '-');
  } else {
    return '';
  }
}

export function flatten(accum: any, value: any): [] {
  return accum.concat(value, []);
}

export function makeUniqueIds(accum: any, unit: any, i: number, units: []): [] {
  if(unit != null){
    if (units.indexOf(unit.id) !== i && accum.indexOf(unit.id) < 0) {
      unit.id = i;
      accum.push(unit);
    }
  }
  return accum;
}

export function getQueryParamsFromLocation(string: string): Object {
  return queryString.parse(string);
}

export function stripTagsFromWagtail(string: string) {
  return string
    .replace(/&#x27;/g, "'")
    .replace(/<p>/g, '')
    .replace(/<\/p>/g, '');
}

export function getAddressFromAddressSet([addressSet]: AddressType):
  | string
  | null {
  if (addressSet) {
    const {street, city, state, postalCode} = addressSet;
    return `${street}, ${city}, ${state}, ${postalCode}`;
  } else return null;
}

export function neighborhoodHasUnits(
  floorplans: FloorplanType[],
  neighborhood: NeighborhoodType,
): boolean | typeof undefined {
  if (floorplans) {
    if (Array.isArray(floorplans) && floorplans.length > 0) {
      const availableFloorplans = floorplans.filter(floorplan => {
        if (floorplan && floorplan.property) {
          return !floorplan.property.soldout;
        } else return null;
      });
      const availableUnits = availableFloorplans
        .map(floorplan => {
          if (floorplan.units) {
            const {units} = floorplan;
            const filteredUnits = units
            .filter(unit => unit.available)
            .map(unit => {
                const mergedUnit = Object.assign({}, floorplan, unit);
                mergedUnit.neighborhood =
                  floorplan.property.address.neighborhood[0].name;
                return mergedUnit;
              });
            return filteredUnits;
          } else return null;
        })
        .reduce(flatten, [])
        .filter(unit => unit?.neighborhood === neighborhood.name);
      return availableUnits.length > 0 ? true : false;
    }
  }
}

export function filterArrayOfObjects(
  array: {}[],
  filterBasedOn: string,
): {}[] | null {
  if (!array || !Array.isArray(array)) return null;
  if (!filterBasedOn) return array;
  return array.filter(
    (obj, pos, arr) =>
      arr.map(mapObj => mapObj[filterBasedOn]).indexOf(obj[filterBasedOn]) ===
      pos,
  );
}

export function getFloorplanName(name: string){
  if(name){
    if(name.indexOf("Residence") == -1){
      let newName = name.indexOf("-") != -1 ? name.replace("FL", "Floors") : name.replace("FL", "Floor"); 
      newName = "Residence " + newName.slice(0, 1) + "," + newName.slice(1)
      return newName;
    }
  }
  return name;
}